import React, { forwardRef } from 'react'
import { css, cx } from 'styled-system/css'
import { type CustomFlexVariantProps, customFlex } from 'styled-system/recipes'
import type { SystemStyleObject } from 'styled-system/types'

type FlexProps = CustomFlexVariantProps & {
  children?: React.ReactNode
  css?: SystemStyleObject
  className?: string
  id?: string
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void
}

export const CustomFlex = forwardRef<HTMLDivElement, FlexProps>((props: FlexProps, forwardRef) => {
  const {
    children,
    className,
    css: cssProp = {},
    direction,
    align,
    justify,
    wrap,
    gap,
    columngap,
    rowgap,
    ...rest
  } = props
  const rootClassName = cx(
    customFlex({
      direction,
      align,
      justify,
      wrap,
      gap,
      columngap,
      rowgap,
    }),
    css(cssProp),
    className,
  )
  return (
    <div {...rest} className={rootClassName} ref={forwardRef}>
      {children}
    </div>
  )
})
CustomFlex.displayName = 'CustomFlex'
