import React, { FC } from 'react'
import { css, cx } from 'styled-system/css'
import { type GutterVariantProps, gutter } from 'styled-system/recipes'
import type { SystemStyleObject } from 'styled-system/types'

type GutterProps = GutterVariantProps & {
  children: React.ReactNode
  css?: SystemStyleObject
  className?: string
  id?: string
}

export const Gutter: FC<GutterProps> = ({
  children,
  className,
  css: cssProp = {},
  variant,
  modificiations,
  ...rest
}) => {
  return (
    <div {...rest} className={cx(css(cssProp), className)}>
      <div
        className={gutter({
          variant,
          modificiations,
        })}>
        {children}
      </div>
    </div>
  )
}
