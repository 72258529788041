'use client'

import { useRouter } from 'next-nprogress-bar'
import { FC, MouseEventHandler, forwardRef, useImperativeHandle, useRef } from 'react'
import { type RecipeVariantProps, css, cva, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { debounce } from '../utils/other-utils'
import { Icon } from './Icon'

export interface ButtonProps {
  as?: 'button' | 'div' | 'a'
  /**
   * Give css class name to button.
   */
  className?: string

  /**
   * Give ID to button.
   */
  id?: string

  /**
   * This prop will be render inside button as icon, great way to add meaning full UI detail to button.
   */
  reactIcon?: React.ReactNode

  /**
   * This prop will be render inside button as icon, great way to add meaning full UI detail to button.
   */
  rightReactIcon?: React.ReactNode

  /**
   * Add label text to button. Ex : Hello World!.
   */
  label?: string

  /**
   * Get a callback when button is clicked.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>

  /**
   * Give css property to button component.
   */
  css?: SystemStyleObject

  /**
   * Button disabled state.
   */

  disabled?: boolean

  /**
   * The stopPropagation is html event stopPropagation property flag. Read More at https://developer.mozilla.org/en-US/docs/Web/API/Event/stopPropagation.
   */
  stopPropagation?: boolean

  /**
   * This button's attributes.
   */
  attributes?: SystemStyleObject

  /**
   * Button Text Color for special case.
   */
  textColor?: string

  /**
   * Possible state of the button. Default to 'default'
   */
  buttonState?: 'default' | 'waiting'

  /**
   * Type of the button.
   */
  buttonType?: 'button' | 'reset' | 'submit'

  fullWidth?: NonNullable<RecipeVariantProps<typeof button>>['fullWidth']

  variant: NonNullable<RecipeVariantProps<typeof button>>['variant']

  routeOnClick?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className = '',
      id,
      fullWidth = false,
      reactIcon = '',
      rightReactIcon = '',
      label = '',
      onClick,
      buttonState = 'default',
      variant = 'primary',
      css: cssProps = {},
      disabled,
      buttonType = 'button',
      as = 'button',
      routeOnClick,
      ...props
    },
    forwardRef,
  ) => {
    const router = useRouter()
    // documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
    // Ref for the button
    const buttonRef = useRef<HTMLButtonElement>(null)
    // Ref for the button container (surround div to apppend span tags used for the ripple effect)
    const rippleContainerRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(forwardRef, () => buttonRef.current as HTMLButtonElement)

    /**
     * Adds ripple effect.
     * documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
     */
    const onAddRipple: MouseEventHandler<HTMLButtonElement> = e => {
      if (buttonRef && buttonRef.current && !disabled && buttonState !== 'waiting') {
        // following doc: https://medium.com/@jh3y/how-to-create-the-ripple-effect-from-google-material-design-c6f993e1d39
        const ripple = buttonRef.current
        const rippleContainer = rippleContainerRef.current
        if (ripple && rippleContainer) {
          const size = ripple.offsetWidth
          const pos = ripple.getBoundingClientRect()
          const rippler = document.createElement('span')
          const x = e.pageX - pos.left - size / 2
          const y = e.pageY - pos.top - size / 2
          const style = `top: ${y}px; left: ${x}px; height: ${size}px; width: ${size}px;`
          rippleContainer.appendChild(rippler)
          rippler.setAttribute('style', style)
        }
      }
    }

    /**
     * Removes the span dom element created by function onAddRipple to create the ripple effect
     * documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
     */
    const cleanUp = () => {
      const container = rippleContainerRef.current
      if (container && rippleContainerRef && rippleContainerRef.current && rippleContainerRef.current.firstChild) {
        while (rippleContainerRef.current.firstChild && container.firstChild) {
          container.removeChild(container.firstChild)
        }
      }
    }

    const onBtnClick: MouseEventHandler<HTMLButtonElement> = e => {
      if (disabled || buttonState === 'waiting') return
      if (onClick) onClick(e)
      if (routeOnClick) router.push(routeOnClick)
    }

    const buttonContentProps = {
      reactIcon,
      rightReactIcon,
      label,
      rippleContainerRef,
    }

    const buttonProps = {
      ref: buttonRef,
      disabled: disabled,
      onMouseDown: onAddRipple,
      onMouseUp: debounce(cleanUp, 2000),
      state: buttonState,
      onClick: onBtnClick,
      id,
      className: cx(button({ variant, state: buttonState, fullWidth, disabled }), css(cssProps), className),
      ...props,
    }

    switch (as) {
      case 'div':
        return (
          // @ts-ignore // TODO: fix this
          <div {...buttonProps}>
            <ButtonContent {...buttonContentProps} />
          </div>
        )
      case 'a':
        return (
          // @ts-ignore // TODO: fix this
          <a {...buttonProps}>
            <ButtonContent {...buttonContentProps} />
          </a>
        )
      default:
        return (
          <button type={buttonType} {...buttonProps}>
            <ButtonContent {...buttonContentProps} />
          </button>
        )
    }
  },
)

Button.displayName = 'Button'

const ButtonContent: FC<{
  reactIcon: React.ReactNode
  rightReactIcon: React.ReactNode
  label: string
  rippleContainerRef: React.RefObject<HTMLDivElement>
}> = ({ reactIcon, rightReactIcon, label, rippleContainerRef }) => {
  return (
    <>
      {reactIcon && <Icon reactIcon={reactIcon} />}
      {(label || reactIcon) && (
        <span
          className={css({
            whiteSpace: 'nowrap',
            ml: reactIcon ? '$2' : '$0',
          })}>
          {label}
        </span>
      )}
      {rightReactIcon && <Icon reactIcon={rightReactIcon} css={{ marginLeft: label ? '12px' : '0px' }} />}
      <span className="ripple--container" ref={rippleContainerRef} />
    </>
  )
}

// documentation on the css
// https://medium.com/@jh3y/how-to-create-the-ripple-effect-from-google-material-design-c6f993e1d39
const button = cva({
  base: {
    // Reset
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    // Custom
    cursor: 'pointer',
    fontFamily: '$button',
    fontWeight: '$button',
    fontStyle: '[{fonts.$button_style}]',
    textTransform: '{fonts.$button_case}',
    lineHeight: '$button',
    fontSize: '$button',
    letterSpacing: '$button',
    py: '$2',
    px: '$4',
    _after: {
      zIndex: '[-1]',
      content: '""',
      position: 'absolute',
      top: '[50%]',
      left: '[50%]',
      width: '[1em]',
      height: '[1em]',
      margin: '[-0.5em]',
      animationName: 'btnLoading',
      animationDuration: '[1s]',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      //   animation: 'btnLoading 1s infinite linear',
      backgroundColor: '$gs1',
      display: 'inline-block',
      boxSizing: 'content-box',
      transformOrigin: '50%',
      transformBox: 'fill-box',
      borderRadius: '$round',
      transitionDuration: '$fast',
      transitionProperty: '[all]',
      opacity: 0,
    },
    '& .ripple--container': {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      transform: 'translateY(0)',
      display: 'block',
    },
    '& .ripple--container span': {
      transform: 'scale(0)',
      borderRadius: '$round',
      position: 'absolute',
      opacity: '0.75',
      backgroundColor: '$gs1',
      animationName: 'btnRipple',
      animationDuration: '[1s]',
    },
  },
  variants: {
    variant: {
      primary: {
        color: '$btnPriText',
        backgroundColor: '$btnPriBg',
        borderTopLeftRadius: '$btnPriTL',
        borderTopRightRadius: '$btnPriTR',
        borderBottomLeftRadius: '$btnPriBL',
        borderBottomRightRadius: '$btnPriBR',
        borderWidth: '$btnPri',
        borderColor: '$btnPriBo',
        borderStyle: 'solid',
        boxShadow:
          '[0 4px 4px {colors.$w11}, 0 1px 2px {colors.$w11}, inset 0 6px 12px {colors.$w11}, inset 0 1px 1px {colors.$w11}]',
        '&:hover': {
          backgroundColor: '$btnPriBg_L',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
        '&:active': {
          backgroundColor: '$btnPriBg_D',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
      },
      secondary: {
        color: '$btnSecText',
        backgroundColor: '$btnSecBg',
        borderTopLeftRadius: '$btnSecTL',
        borderTopRightRadius: '$btnSecTR',
        borderBottomLeftRadius: '$btnSecBL',
        borderBottomRightRadius: '$btnSecBR',
        borderWidth: '$btnSec',
        borderColor: '$btnSecBo',
        borderStyle: 'solid',
        boxShadow:
          '[0 4px 4px {colors.$w11}, 0 1px 2px {colors.$w11}, inset 0 6px 12px {colors.$w11}, inset 0 1px 1px {colors.$w11}]',
        '&:hover': {
          backgroundColor: '$btnSecBg_L',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
        '&:active': {
          backgroundColor: '$btnSecBg_D',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
      },
      tertiary: {
        color: '$btnTerText',
        backgroundColor: '$btnTerBg',
        borderTopLeftRadius: '$btnTerTL',
        borderTopRightRadius: '$btnTerTR',
        borderBottomLeftRadius: '$btnTerBL',
        borderBottomRightRadius: '$btnTerBR',
        borderWidth: '$btnTer',
        borderColor: '$btnTerBo',
        borderStyle: 'solid',
        // boxShadow:
        //   '0 4px 4px $colors$b11, 0 1px 2px $colors$b11, inset 0 6px 12px $colors$b11, inset 0 1px 1px $colors$b11',
        '&:hover': {
          backgroundColor: '$btnTerBg_L',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
        '&:active': {
          backgroundColor: '$btnTerBg_D',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
      },
      ghost: {
        color: '$btnGhoText',
        backgroundColor: '$btnGhoBg',
        borderTopLeftRadius: '$btnGhoTL',
        borderTopRightRadius: '$btnGhoTR',
        borderBottomLeftRadius: '$btnGhoBL',
        borderBottomRightRadius: '$btnGhoBR',
        borderWidth: '$btnGho',
        borderColor: '$btnGhoBo',
        borderStyle: 'solid',
        '&:hover': {
          backgroundColor: '$btnGhoBg_L',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
        '&:active': {
          backgroundColor: '$btnGhoBg_D',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
      },
    },
    state: {
      default: {},
      waiting: {
        cursor: 'initial',
        '&:after': {
          zIndex: '[1]',
          opacity: 1,
          transition: '[all] [$fast] [ease]',
        },
      },
    },
    fullWidth: {
      true: { width: '$full' },
      false: { width: '[fit-content]' },
    },
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      state: 'waiting',
      variant: 'primary',
      css: {
        '&:hover': {
          backgroundColor: '$btnPriBg',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
      },
    },
    {
      state: 'waiting',
      variant: 'secondary',
      css: {
        '&:hover': {
          backgroundColor: '$btnSecBg',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
      },
    },
    {
      state: 'waiting',
      variant: 'tertiary',
      css: {
        '&:hover': {
          backgroundColor: '$btnTerBg',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
      },
    },
    {
      disabled: true,
      variant: 'primary',
      css: {
        backgroundColor: '$gs8',
        color: '$gs9',
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$gs8',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
          color: '$gs9',
        },
      },
    },
    {
      disabled: true,
      variant: 'secondary',
      css: {
        backgroundColor: '$gs8',
        color: '$gs9',
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$gs8',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
          color: '$gs9',
        },
      },
    },
    {
      disabled: true,
      variant: 'ghost',
      css: {
        backgroundColor: '$none',
        color: '$gs8',
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$none',
          color: '$gs8',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
      },
    },
    {
      disabled: true,
      variant: 'tertiary',
      css: {
        backgroundColor: '$gs2',
        color: '$gs8',
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$gs2',
          color: '$gs8',
          transitionProperty: '[all]',
          transitionDuration: '$fast',
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'primary',
    state: 'default',
    fullWidth: false,
    disabled: false,
  },
})
