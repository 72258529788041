'use client'

import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import Link from 'next/link'
import { css } from 'styled-system/css'

export default function SiteError() {
  return (
    <div className={container}>
      <CustomFlex align="center" direction={'column'} justify={'center'}>
        <Gutter>
          <CustomFlex align="center" direction={'column'} justify={'center'} gap={'5'}>
            <Text variant="h1">500</Text>
            <Text variant="body1">
              Oh eyeballs! Something went wrong. We're <i>looking</i> to see what happened.
            </Text>
            <Link href="/" style={{ textDecoration: 'none' }}>
              <Button label="Go Back" variant="primary" />
            </Link>
          </CustomFlex>
        </Gutter>
      </CustomFlex>
    </div>
  )
}

const container = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  minHeight: '[100dvh]',
  minWidth: '[100dvw]',
})
