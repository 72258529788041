import { css, cx } from 'styled-system/css'
import { type TextVariantProps, text } from 'styled-system/recipes'
import type { SystemStyleObject } from 'styled-system/types'

type TextProps = TextVariantProps & {
  children: React.ReactNode
  css?: SystemStyleObject
  className?: string
  onMouseDown?: (event: React.MouseEvent<HTMLSpanElement>) => void
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void
  id?: string
}
export const Text = (props: TextProps) => {
  const { children, className, css: cssProp = {}, oll, allowNaturalMb, isLink, variant, ...rest } = props
  const rootClassName = cx(
    text({
      oll,
      allowNaturalMb,
      isLink,
      variant,
    }),
    css(cssProp),
    className,
  )
  return (
    <span {...rest} className={rootClassName}>
      {children}
    </span>
  )
}
